// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
// @import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.aliyun.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

.app-container {
    min-height: 320px;
    overflow: hidden;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}